import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import { Page } from 'src/constant/Page';
import { BidOrder } from 'src/model/Order';
import { bn, bnFormat } from 'src/util/bigNumber';

type Props = {
  data: BidOrder[];
};

const Card = ({ data }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const elementMapFunction = (order: BidOrder) => (
    <div key={order.id}>
      <div className="relative flex flex-col gap-[4px] px-[0px] py-[15px]">
        <div className="flex flex-row gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('orderHistory.desc.orderId')}
          </div>
          <div>{order.id}</div>
        </div>
        <div className="flex flex-row gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('orderHistory.desc.createDate')}
          </div>
          <div>{format(Date.parse(order.createdAt), 'yyyy/MM/dd HH:mm:ss')}</div>
        </div>
        <div className="flex flex-row gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('orderHistory.desc.orderAmount')}
          </div>
          <div>{`${bnFormat(order.amount)} ${order.base.toUpperCase()}`}</div>
        </div>
        <div className="flex flex-row gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('orderHistory.desc.filledAmount')}
          </div>
          <div>{`${bnFormat(order.filledAmount)} ${order.base.toUpperCase()}`}</div>
        </div>
        <div className="flex flex-row gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('orderHistory.desc.sentAmount')}
          </div>
          <div>{`${bnFormat(order.sentAmount)} ${order.base.toUpperCase()}`}</div>
        </div>
        <div className="flex flex-row gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('orderHistory.desc.unitPrice')}
          </div>
          <div>{`${bnFormat(order.price)} ${order.quote.toUpperCase()}`}</div>
        </div>
        <div className="flex flex-row gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('orderHistory.desc.filledTotal')}
          </div>
          <div>{`${bnFormat(order.filledTotal)} ${order.quote.toUpperCase()}`}</div>
        </div>
        <div className="flex flex-row gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('orderHistory.desc.payment')}
          </div>
          <div>{`${bnFormat(order.sentTotal)} ${order.quote.toUpperCase()}`}</div>
        </div>
        <div className="flex flex-row gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('orderHistory.desc.filledSentTotal')}
          </div>
          <div>{`${bn(order.filledTotal)
            .minus(order.sentTotal)
            .toFormat()} ${order.quote.toUpperCase()}`}</div>
        </div>
        <div className="flex flex-row gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('orderHistory.desc.completeDate')}
          </div>
          <div>
            {order.completedAt ? format(Date.parse(order.completedAt), 'yyyy/MM/dd HH:mm:ss') : '-'}
          </div>
        </div>
        <Button
          appearance="text"
          className="absolute bottom-[15px] right-0 font-bold"
          onClick={() => navigate(`${Page.Order}/${order.id}`)}
        >
          {t('orderHistory.act.detail')}
        </Button>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
    </div>
  );

  return <div>{data.map(elementMapFunction)}</div>;
};

export default Card;
