import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import Pagination from 'src/component/Pagination';
import SelectOption from 'src/component/SelectOption';
import SortingSelect from 'src/component/SortingSelect';
import { MediaQuery } from 'src/constant/Media';
import { Page } from 'src/constant/Page';
import { ThemeContext } from 'src/context/ThemeContext';
import { usePagination } from 'src/hook/usePagination';
import { BidOrder } from 'src/model/Order';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { getBidOrders } from 'src/service/orderService';
import Card from './component/Card';
import Table from './component/Table';

const OrderBidPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orderBid: filter } = useSelector((rootState: RootState) => rootState.ui.filter);
  const navigate = useNavigate();
  const [filterSorting, setFilterSorting] = useState<string>(filter.sorting ?? 'default');
  const { limit, offset, setCount, paginationProps } = usePagination({ defaultPage: filter.page });
  const [orderArray, setOrderArray] = useState<Array<BidOrder>>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);
  const { IcEmpty } = useContext(ThemeContext).image;

  useEffect(() => {
    getBidOrders(filterSorting, { limit, offset })
      .then((res) => {
        setOrderArray(res.data);
        setCount(res.count);
      })
      .catch((e) => {
        dispatch(openSnackbar({ message: e, severity: 'alert' }));
      });
  }, [refresh, filterSorting, limit, offset]);

  const onSortingFilterChange = (value: string) => {
    paginationProps.setPage(1);
    setFilterSorting(value);
  };

  return (
    <div>
      <BackButton />
      <div className="mb-[15px] mt-[10px] flex flex-row items-start justify-between text-[28px] font-bold sm:mt-[20px] sm:text-[32px]">
        {t('order.heading')}
        <Button appearance="text" onClick={() => navigate(Page.OrderHistory)}>
          {t('order.act.history')}
        </Button>
      </div>
      <div className="text-end">
        <Button appearance="outlined" onClick={() => setRefresh(!refresh)}>
          {t('order.act.refresh')}
        </Button>
      </div>
      {orderArray && (
        <>
          <div className="mt-[15px] rounded-[12px] bg-white px-[15px] pb-[25px] pt-[10px] dark:bg-black-800 xs:px-[40px] xs:pb-[25px] xs:pt-[10px]">
            <div className="flex justify-end px-[0px] py-[15px]">
              <SortingSelect
                defaultValue={filter.sorting ?? 'default'}
                onChange={onSortingFilterChange}
              >
                <SelectOption value="default">{t('order.desc.sortingOption.default')}</SelectOption>
                <SelectOption value="-price">
                  {t('order.desc.sortingOption.priceDesc')}
                </SelectOption>
                <SelectOption value="price">{t('order.desc.sortingOption.priceAsce')}</SelectOption>
                <SelectOption value="-sent_amount">
                  {t('order.desc.sortingOption.sentAmountDesc')}
                </SelectOption>
                <SelectOption value="sent_amount">
                  {t('order.desc.sortingOption.sentAmountAsce')}
                </SelectOption>
                <SelectOption value="-sent_total">
                  {t('order.desc.sortingOption.sentTotalDesc')}
                </SelectOption>
                <SelectOption value="sent_total">
                  {t('order.desc.sortingOption.sentTotalAsce')}
                </SelectOption>
                <SelectOption value="-filled_amount">
                  {t('order.desc.sortingOption.filledAmountDesc')}
                </SelectOption>
                <SelectOption value="filled_amount">
                  {t('order.desc.sortingOption.filledAmountAsce')}
                </SelectOption>
                <SelectOption value="-filled_total">
                  {t('order.desc.sortingOption.filledTotalDesc')}
                </SelectOption>
                <SelectOption value="filled_total">
                  {t('order.desc.sortingOption.filledTotalAsce')}
                </SelectOption>
              </SortingSelect>
            </div>
            <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
            {orderArray.length === 0 && (
              <div className="text-center">
                <img src={IcEmpty} />
              </div>
            )}
            {orderArray.length > 0 &&
              (isBiggerThanMd ? <Table data={orderArray} /> : <Card data={orderArray} />)}
          </div>
          <div className="mt-[20px] flex flex-row-reverse">
            <Pagination {...paginationProps} />
          </div>
        </>
      )}
    </div>
  );
};

export default OrderBidPage;
