import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import Modal from 'src/component/Modal';
import { ThemeContext } from 'src/context/ThemeContext';

type Props = {
  open: boolean;
  onClose: () => void;
};

const NoResultModal = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const { IcNoticeError } = useContext(ThemeContext).image;

  return (
    <Modal open={open} handleClose={onClose}>
      <div>
        <div className="flex flex-row gap-[10px] text-[16px]">
          <img src={IcNoticeError} />
          <div>{t('trade.desc.noResult')}</div>
        </div>
        <div className="mt-[40px] text-right">
          <Button onClick={() => onClose()}>{t('trade.act.ok')}</Button>
        </div>
      </div>
    </Modal>
  );
};

export default NoResultModal;
