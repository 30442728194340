import { format } from 'date-fns';
import { useCallback, useContext, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button from 'src/component/Button';
import Image from 'src/component/Image';
import { ThemeContext } from 'src/context/ThemeContext';
import { Fill } from 'src/model/Order';
import { openSnackbar } from 'src/redux/uiSlice';
import { unsendFill, uploadReceipt } from 'src/service/orderService';
import { bnFormat } from 'src/util/bigNumber';
import UnsendModal from './UnsendModal';
import UploadModal from './UploadModal';

type Props = {
  fillsArray?: Array<Fill>;
  onRefresh: () => void;
  className?: string;
};
const OrderFillList = ({ fillsArray, onRefresh, className }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [receiptUrl, setReceiptUrl] = useState<string>();
  const { IcCopy, IcSearch } = useContext(ThemeContext).image;
  const [uploadId, setUploadId] = useState<string>('');
  const [unsendFillId, setUnsendFillId] = useState<string>();

  const createCopyAllString = useCallback(
    (index: number) => {
      if (!fillsArray) return '';

      const fill = fillsArray[index];
      const isBank = fill.bankAccount.paymentMethodType === 'bank';

      return isBank
        ? `${t('orderDetail.desc.accountName')} ${fill.bankAccount.name}\n${t(
            'orderDetail.desc.accountNumber',
          )} ${fill.bankAccount.account}\n${t('orderDetail.desc.total')} ${bnFormat(
            fill.total,
          )}\n${t('orderDetail.desc.bankName')} ${fill.bankAccount.bankName}\n${t(
            'orderDetail.desc.bankBranchName',
          )} ${fill.bankAccount.bankBranchName}`
        : `${t('orderDetail.desc.alipayAccountName')} ${fill.bankAccount.name}\n${t(
            'orderDetail.desc.alipayAccount',
          )} ${fill.bankAccount.account}\n${t('orderDetail.desc.total')} ${bnFormat(fill.total)}`;
    },
    [t, fillsArray],
  );

  const onCopy = () => {
    dispatch(openSnackbar({ message: t('message.copied') }));
  };

  const onSubmitReceipt = useCallback(
    (file: File) => {
      setUploadId('');
      uploadReceipt(uploadId, file)
        .then(() => {
          onRefresh();
        })
        .catch((e) => {
          dispatch(openSnackbar({ message: e, severity: 'alert' }));
        });
    },
    [uploadId, onRefresh],
  );

  const onCheckReceipt = (fill: Fill) => {
    if (fill.receipts.length === 0) return;

    setReceiptUrl(fill.receipts[0].url);
  };

  const onUnsendClick = (id: string) => () => setUnsendFillId(id);

  const onUnsend = () => {
    if (unsendFillId === undefined) return;
    setUnsendFillId(undefined);
    unsendFill(unsendFillId)
      .then(() => {
        onRefresh();
      })
      .catch((e) => {
        dispatch(openSnackbar({ message: e, severity: 'alert' }));
      });
  };

  const [stateQRCode, setQRCode] = useState('');

  const elementMapFunction = (orderFill: Fill, index: number) => {
    const isBank = orderFill.bankAccount.paymentMethodType === 'bank';
    const isAlipay = orderFill.bankAccount.paymentMethodType === 'alipay';

    return (
      <div key={orderFill.id} className="pt-[15px]">
        {orderFill.status === 'canceled' && (
          <div className="flex flex-col gap-[5px]">
            <div className="flex text-[12px]">
              <div className="w-1/3 text-grey-700 dark:text-grey-300">
                {t('orderDetail.desc.orderId')}
              </div>
              <div className="w-2/3">{orderFill.id}</div>
            </div>
            <div className="relative flex text-[12px]">
              <div className="w-1/3 text-grey-700 dark:text-grey-300">
                {t('orderDetail.desc.total')}
              </div>
              <div className="w-2/3 !text-[13px] font-bold text-turquoise dark:text-aqua">
                {bnFormat(orderFill.total)}
              </div>
              <CopyToClipboard text={bnFormat(orderFill.total)}>
                <img src={IcCopy} className="absolute right-[0px] top-[0px] cursor-pointer" />
              </CopyToClipboard>
            </div>
          </div>
        )}
        {orderFill.status !== 'canceled' && (
          <div className="flex flex-col gap-[5px]">
            <div className="flex text-[12px]">
              <div className="w-1/3 text-grey-700 dark:text-grey-300">
                {t('orderDetail.desc.orderId')}
              </div>
              <div className="w-2/3">{orderFill.id}</div>
            </div>
            <div className="relative flex text-[12px]">
              <div className="w-1/3 text-grey-700 dark:text-grey-300">
                {isBank
                  ? t('orderDetail.desc.accountName')
                  : t('orderDetail.desc.alipayAccountName')}
              </div>
              <div className="w-2/3">{orderFill.bankAccount.name}</div>
              <CopyToClipboard text={orderFill.bankAccount.name} onCopy={onCopy}>
                <img src={IcCopy} className="absolute right-[0px] top-[0px] cursor-pointer" />
              </CopyToClipboard>
            </div>
            <div className="relative flex text-[12px]">
              <div className="w-1/3 text-grey-700 dark:text-grey-300">
                {isBank ? t('orderDetail.desc.accountNumber') : t('orderDetail.desc.alipayAccount')}
              </div>
              <div className="w-2/3">{orderFill.bankAccount.account}</div>
              <CopyToClipboard text={orderFill.bankAccount.account} onCopy={onCopy}>
                <img src={IcCopy} className="absolute right-[0px] top-[0px] cursor-pointer" />
              </CopyToClipboard>
            </div>
            <div className="relative flex text-[12px]">
              <div className="w-1/3 text-grey-700 dark:text-grey-300">
                {t('orderDetail.desc.total')}
              </div>
              <div className="w-2/3 !text-[13px] font-bold text-turquoise dark:text-aqua">
                {bnFormat(orderFill.total)}
              </div>
              <CopyToClipboard text={bnFormat(orderFill.total)} onCopy={onCopy}>
                <img src={IcCopy} className="absolute right-[0px] top-[0px] cursor-pointer" />
              </CopyToClipboard>
            </div>
            {isBank && (
              <div className="relative flex text-[12px]">
                <div className="w-1/3 text-grey-700 dark:text-grey-300">
                  {t('orderDetail.desc.bankName')}
                </div>
                <div className="w-2/3">{orderFill.bankAccount.bankName}</div>
                <CopyToClipboard text={orderFill.bankAccount.bankName} onCopy={onCopy}>
                  <img src={IcCopy} className="absolute right-[0px] top-[0px] cursor-pointer" />
                </CopyToClipboard>
              </div>
            )}
            {isBank && (
              <div className="relative flex text-[12px]">
                <div className="w-1/3 text-grey-700 dark:text-grey-300">
                  {t('orderDetail.desc.bankBranchName')}
                </div>
                <div className="w-2/3">{orderFill.bankAccount.bankBranchName}</div>
                <CopyToClipboard text={orderFill.bankAccount.bankBranchName} onCopy={onCopy}>
                  <img src={IcCopy} className="absolute right-[0px] top-[0px] cursor-pointer" />
                </CopyToClipboard>
              </div>
            )}
          </div>
        )}
        {orderFill.status === 'executed' && (
          <div className="mt-[15px]">
            {orderFill.rejectedAt && (
              <div className="mb-[5px] text-right text-[12px] text-crimson dark:text-red md:hidden">
                {t('orderDetail.desc.rejectHint')}
              </div>
            )}
            <div className="flex flex-col justify-between gap-[5px]">
              <div>
                <CopyToClipboard text={createCopyAllString(index)} onCopy={onCopy}>
                  <Button appearance="outlined" size="small">
                    {t('orderDetail.act.copyAll')}
                  </Button>
                </CopyToClipboard>
              </div>
              {isAlipay && orderFill.bankAccount.imageUrl ? (
                <div className={'mt-[8px] flex flex-row text-[12px]'}>
                  <div className="w-1/3 text-grey-700 dark:text-grey-300">
                    {t('orderDetail.desc.alipayQRCode')}
                  </div>
                  <div className="w-2/3 cursor-pointer">
                    <img
                      className={'max-w-[90px]'}
                      src={orderFill.bankAccount.imageUrl}
                      alt="qrcode"
                      onClick={() => {
                        if (orderFill.bankAccount.imageUrl)
                          setQRCode(orderFill.bankAccount.imageUrl);
                      }}
                    />
                  </div>
                </div>
              ) : null}
              <div className="flex items-center justify-end gap-[5px]">
                {orderFill.rejectedAt && (
                  <div className="mb-[5px] hidden text-right text-[12px] text-crimson dark:text-red md:block">
                    {t('orderDetail.desc.rejectHint')}
                  </div>
                )}
                <Button
                  appearance="secondary"
                  size="small"
                  onClick={() => setUploadId(orderFill.id)}
                >
                  {t('orderDetail.act.submitReceipt')}
                </Button>
              </div>
            </div>
          </div>
        )}
        {orderFill.sentAt && (
          <div className="mt-[15px] flex justify-between text-[12px]">
            <div className="flex gap-[10px]">
              {orderFill.status === 'sent' && (
                <div className="text-khaki dark:text-yellow">
                  {t('orderDetail.desc.waitingConfirm')}
                </div>
              )}
              {orderFill.status === 'received' && (
                <div className="text-green dark:text-lime">
                  {t('orderDetail.desc.confirmedBySeller')}
                </div>
              )}
              {orderFill.status === 'sent' && (
                <div
                  className="h-fit cursor-pointer font-bold text-crimson dark:text-red"
                  onClick={onUnsendClick(orderFill.id)}
                >
                  {orderFill.receipts.length > 0
                    ? t('orderDetail.desc.unsend')
                    : t('orderDetail.desc.revokeClaim')}
                </div>
              )}
            </div>
            <div className="flex flex-col items-end">
              {orderFill.receipts.length > 0 ? (
                <div
                  className="flex cursor-pointer items-center"
                  onClick={() => onCheckReceipt(orderFill)}
                >
                  {t('orderDetail.act.checkReceipt')}
                  <img src={IcSearch} />
                </div>
              ) : (
                <div>{t('orderDetail.act.paymentClaimed')}</div>
              )}
              {orderFill.sentAt && (
                <div>{format(Date.parse(orderFill.sentAt), 'yyyy/MM/dd HH:mm:ss')}</div>
              )}
            </div>
          </div>
        )}
        <div className="mt-[15px] h-[1px] bg-light-200 dark:bg-dark-700" />
      </div>
    );
  };

  return (
    <>
      <div className={className}>{fillsArray && fillsArray.map(elementMapFunction)}</div>
      <UploadModal
        open={uploadId.length > 0}
        onClose={() => setUploadId('')}
        onSubmit={onSubmitReceipt}
      />
      {receiptUrl && (
        <Image open={true} src={receiptUrl} onClose={() => setReceiptUrl(undefined)} />
      )}
      <UnsendModal
        open={unsendFillId !== undefined}
        onClose={() => setUnsendFillId(undefined)}
        onSubmit={onUnsend}
      />
      {stateQRCode ? (
        <Image
          src={stateQRCode}
          open={!!stateQRCode}
          onClose={() => {
            setQRCode('');
          }}
        />
      ) : null}
    </>
  );
};

export default OrderFillList;
