import { useEffect, useMemo, useState } from 'react';
import { useForm, useFormState, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormNumberInput from 'src/component/FormNumberInput';
import FormSelect from 'src/component/FormSelect';
import SelectOption from 'src/component/SelectOption';
import { Page } from 'src/constant/Page';
import { BidRequestForm } from 'src/model/Form';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { createBidOrder, initOrderBid } from 'src/service/orderService';
import { bn, bnFormat } from 'src/util/bigNumber';
import NoResultModal from './component/NoResultModal';

const TradeBid = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { crypto, fiat } = useSelector((state: RootState) => state.coin);
  const fiatIcon = useMemo(() => fiat.find((value) => value.id === 'cny')?.icon ?? '', [fiat]);
  const hint = useMemo(() => {
    if (crypto.length === 0) return '';
    const minOrderAmount = crypto[0].minOrderAmount;
    const maxOrderAmount = crypto[0].maxOrderAmount;
    const coin = crypto.length > 0 ? crypto[0].id.toUpperCase() : '';
    if (minOrderAmount && maxOrderAmount)
      return `${t('trade.desc.orderAmount')} ${bnFormat(minOrderAmount)} ${coin} - ${bnFormat(
        maxOrderAmount,
      )} ${coin}`;
    if (minOrderAmount && maxOrderAmount === null)
      return `${t('trade.desc.orderAmount')} ${bnFormat(minOrderAmount)} ${coin} -`;
    if (minOrderAmount === null && maxOrderAmount)
      return `${t('trade.desc.orderAmount')} 0 - ${bnFormat(maxOrderAmount)} ${coin}`;

    return '';
  }, [t, crypto]);
  const methods = useForm<BidRequestForm>();
  const [marketPrice, setMarketPrice] = useState<string>();
  const [isNoResultShowing, setNoResultShowing] = useState<boolean>(false);
  const formData = useWatch({ control: methods.control });
  const { dirtyFields } = useFormState({ control: methods.control });

  const onSubmit = (data: BidRequestForm) => {
    if (!marketPrice) return;
    createBidOrder(data, marketPrice)
      .then((res) => {
        navigate(`${Page.Order}/${res.id}`, { state: res });
      })
      .catch((e) => {
        if (e === t('error.order.noOrderToMatch')) setNoResultShowing(true);
        dispatch(openSnackbar({ message: e, severity: 'alert' }));
      });
  };

  useEffect(() => {
    initOrderBid('usdt', 'cny')
      .then((res) => setMarketPrice(bnFormat(res.price)))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, []);

  useEffect(() => {
    if (dirtyFields.quote !== true || marketPrice === undefined || crypto.length === 0) return;
    const quoteNumber = bn(methods.getValues()['quote']);
    methods.resetField('base');
    if (quoteNumber.isGreaterThan(0))
      methods.setValue('base', quoteNumber.div(marketPrice).toFixed(crypto[0].decimal, 1), {
        shouldDirty: false,
      });
  }, [formData.quote, dirtyFields.quote, crypto]);

  useEffect(() => {
    if (dirtyFields.base !== true || marketPrice === undefined || fiat.length === 0) return;
    const baseNumber = bn(methods.getValues()['base']);
    methods.resetField('quote');
    if (baseNumber.isGreaterThan(0))
      methods.setValue('quote', baseNumber.times(marketPrice).toFixed(fiat[0].decimal, 0), {
        shouldDirty: false,
      });
  }, [formData.base, dirtyFields.base, fiat]);

  useEffect(() => {
    if (!formData.base || crypto.length === 0) return;

    const minOrderAmount = crypto[0].minOrderAmount;
    const maxOrderAmount = crypto[0].maxOrderAmount;
    if (minOrderAmount && bn(formData.base).lt(minOrderAmount)) methods.setError('base', {});
    else if (maxOrderAmount && bn(formData.base).gt(maxOrderAmount)) methods.setError('base', {});
    else methods.clearErrors('base');
  }, [formData.base, crypto]);

  return (
    <div>
      <BackButton />
      <div className="mb-[30px] mt-[10px] text-[28px] font-bold sm:mt-[20px] sm:text-[32px]">
        {t('trade.heading')}
      </div>
      {fiat.length > 0 && crypto.length > 0 && (
        <Form
          methods={methods}
          onSubmit={onSubmit}
          className="box-border rounded-[12px] bg-white px-[40px] pb-[70px] pt-[40px] dark:bg-black-800 md:w-1/2"
        >
          <div className="relative">
            <FormNumberInput
              label={t('trade.desc.pay')}
              name="quote"
              decimal={fiat[0].decimal}
              required
            />
            <div className="absolute right-[0px] top-[28px] text-grey-700 dark:text-grey-300">
              {fiat[0].id.toUpperCase()}
            </div>
          </div>
          <div className="relative mt-[45px]">
            <FormNumberInput
              name="base"
              label={t('trade.desc.receive')}
              hint={hint}
              decimal={crypto[0].decimal}
              required
            />
            <div className="absolute right-[0px] top-[28px] text-grey-700 dark:text-grey-300">
              {crypto[0].id.toUpperCase()}
            </div>
          </div>
          <div className="mt-[25px] text-[14px] text-dark-700 dark:text-grey-200">{`${t(
            'trade.desc.marketPrice',
          )} ${marketPrice ? `${fiatIcon} ${marketPrice}` : '-'}`}</div>
          <div className="mt-5">
            <FormSelect
              label={t('trade.desc.paymentMethod')}
              name="paymentMethodType"
              defaultValue="all"
              required
            >
              <SelectOption value="all">{t('trade.desc.paymentMethodDisplay.all')}</SelectOption>
              <SelectOption value="bank">{t('trade.desc.paymentMethodDisplay.bank')}</SelectOption>
              <SelectOption value="alipay">
                {t('trade.desc.paymentMethodDisplay.alipay')}
              </SelectOption>
            </FormSelect>
          </div>
          <div className="mt-[45px] text-right">
            <Button
              type="submit"
              disabled={
                !formData.base ||
                !formData.quote ||
                Object.keys(methods.formState.errors).length !== 0
              }
            >
              {t('trade.act.pairOrder')}
            </Button>
          </div>
        </Form>
      )}
      <NoResultModal open={isNoResultShowing} onClose={() => setNoResultShowing(false)} />
    </div>
  );
};

export default TradeBid;
