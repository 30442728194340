import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Page } from './constant/Page';
import Account from './page/account/Account';
import AddressManagement from './page/addressManagement/AddressManagement';
import Announcement from './page/announcement/Announcement';
import AnnouncementDetail from './page/announcementDetail/AnnouncementDetail';
import ForgetPassword from './page/authForgetPassword/ForgetPassword';
import SignIn from './page/authSignIn/SignIn';
import SignOut from './page/authSignOut/SignOut';
import ContactUs from './page/contactUs/ContactUs';
import Deposit from './page/deposit/Deposit';
import DepositDetail from './page/depositDetail/DepositDetail';
import EnableTfa from './page/enableTfa/EnableTfa';
import LandingBid from './page/landingBid/LandingBid';
import OrderBid from './page/orderBid/OrderBid';
import OrderBidDetail from './page/orderBidDetail/OrderBidDetail';
import OrderBidHistory from './page/orderBidHistory/OrderBidHistory';
import Privacy from './page/privacy/Privacy';
import Property from './page/property/Property';
import Record from './page/record/Record';
import Support from './page/support/Support';
import TermsOfUse from './page/termsOfUse/TermsOfUse';
import TradeBid from './page/tradeBid/TradeBid';
import Withdrawal from './page/withdrawal/Withdrawal';
import WithdrawalDetail from './page/withdrawalDetail/WithdrawalDetail';
import WithdrawalSuccess from './page/withdrawalSuccess/WithdrawalSuccess';
import { RootState } from './redux/store';

const AppRoutes = () => {
  const { isLogin } = useSelector((state: RootState) => state.auth);
  const location = useLocation();

  return (
    <Routes>
      <Route path={Page.Landing} element={<LandingBid />} />
      <Route path={Page.TermsOfUse} element={<TermsOfUse />} />
      <Route path={Page.Privacy} element={<Privacy />} />
      {!isLogin && (
        <>
          <Route path={Page.SignIn} element={<SignIn />} />
          <Route path={Page.ForgetPassword} element={<ForgetPassword />} />
          <Route
            path="*"
            element={<Navigate to={Page.SignIn} state={{ from: location.pathname }} />}
          />
        </>
      )}
      {isLogin && (
        <>
          <Route path={Page.SignOut} element={<SignOut />} />
          <Route path={Page.Trade} element={<TradeBid />} />
          <Route path={Page.Order} element={<OrderBid />} />
          <Route path={`${Page.Order}/:orderId`} element={<OrderBidDetail />} />
          <Route path={Page.OrderHistory} element={<OrderBidHistory />} />
          <Route path={Page.Property} element={<Property />} />
          <Route path={Page.Deposit} element={<Deposit />} />
          <Route path={`${Page.Deposit}/:recordId`} element={<DepositDetail />} />
          <Route path={Page.Withdrawal} element={<Withdrawal />} />
          <Route path={`${Page.Withdrawal}/:recordId`} element={<WithdrawalDetail />} />
          <Route path={Page.WithdrawalSuccess} element={<WithdrawalSuccess />} />
          <Route path={Page.AddressManagement} element={<AddressManagement />} />
          <Route path={Page.Record} element={<Record />} />
          <Route path={Page.Account} element={<Account />} />
          <Route path={Page.EnableTfa} element={<EnableTfa />} />
          <Route path={Page.Announcement} element={<Announcement />} />
          <Route path={`${Page.Announcement}/:id`} element={<AnnouncementDetail />} />
          <Route path={Page.Support} element={<Support />} />
          <Route path={Page.ContactUs} element={<ContactUs />} />
        </>
      )}
      <Route path="*" element={<Navigate to={Page.Landing} />} />
    </Routes>
  );
};

export default AppRoutes;
