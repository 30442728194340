import classNames from 'classnames';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import { Page } from 'src/constant/Page';
import { BidOrder } from 'src/model/Order';
import { bn, bnFormat } from 'src/util/bigNumber';

type Props = {
  data: BidOrder[];
};

const Table = ({ data }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const elementMapFunction = (order: BidOrder) => (
    <div key={order.id}>
      <div className="flex flex-row">
        <div
          className={classNames(
            'box-border flex flex-col gap-[8px] py-[16px] pl-[0px] pr-[12px] text-[14px]',
            'break-all',
            'w-1/12',
          )}
        >
          {order.id}
        </div>
        <div
          className={classNames(
            'box-border flex flex-col gap-[8px] py-[16px] pl-[0px] pr-[12px] text-[14px]',
            'w-1/6',
          )}
        >
          {format(Date.parse(order.createdAt), 'yyyy/MM/dd HH:mm:ss')}
        </div>
        <div
          className={classNames(
            'box-border flex flex-col gap-[8px] py-[16px] pl-[0px] pr-[12px] text-[14px]',
            'w-1/6',
          )}
        >
          <div>
            <div className="font-bold text-grey-700 dark:text-grey-300">
              {t('order.desc.orderAmount')}
            </div>
            <div>
              <span>{`${bnFormat(order.amount)} `}</span>
              <span className="break-keep">{order.base.toUpperCase()}</span>
            </div>
          </div>
          <div>
            <div className="font-bold text-grey-700 dark:text-grey-300">
              {t('order.desc.filledAmount')}
            </div>
            <div>
              <span>{`${bnFormat(order.filledAmount)} `}</span>
              <span className="break-keep">{order.base.toUpperCase()}</span>
            </div>
          </div>
          <div>
            <div className="font-bold text-grey-700 dark:text-grey-300">
              {t('order.desc.sentAmount')}
            </div>
            <div>
              <span>{`${bnFormat(order.sentAmount)} `}</span>
              <span className="break-keep">{order.base.toUpperCase()}</span>
            </div>
          </div>
        </div>
        <div
          className={classNames(
            'box-border flex flex-col gap-[8px] py-[16px] pl-[0px] pr-[12px] text-[14px]',
            'w-1/12',
          )}
        >{`${bnFormat(order.price)} ${order.quote.toUpperCase()}`}</div>
        <div
          className={classNames(
            'box-border flex flex-col gap-[8px] py-[16px] pl-[0px] pr-[12px] text-[14px]',
            'w-1/6',
          )}
        >
          <div>
            <div className="font-bold text-grey-700 dark:text-grey-300">
              {t('order.desc.filledTotal')}
            </div>
            <div>
              <span>{`${bnFormat(order.filledTotal)} `}</span>
              <span className="break-keep">{order.quote.toUpperCase()}</span>
            </div>
          </div>
          <div>
            <div className="font-bold text-grey-700 dark:text-grey-300">
              {t('order.desc.paidTotal')}
            </div>
            <div>
              <span>{`${bnFormat(order.sentTotal)} `}</span>
              <span className="break-keep">{order.quote.toUpperCase()}</span>
            </div>
          </div>
        </div>
        <div
          className={classNames(
            'box-border flex flex-col gap-[8px] py-[16px] pl-[0px] pr-[12px] text-[14px]',
            'w-1/6',
          )}
        >{`${bn(order.filledTotal)
          .minus(bn(order.sentTotal))
          .toFormat()} ${order.quote.toUpperCase()}`}</div>
        <div
          className={classNames(
            'box-border flex flex-col gap-[8px] py-[16px] pl-[0px] pr-[12px] text-[14px]',
            'text-green dark:text-lime',
            'w-1/12',
          )}
        >
          {t(`order.desc.statusDisplay.${order.status}`)}
        </div>
        <div
          className={classNames(
            'box-border flex flex-col gap-[8px] py-[16px] pl-[0px] pr-[12px] text-[14px]',
            'text-center',
            'w-1/12',
          )}
        >
          <Button appearance="text" onClick={() => navigate(`${Page.Order}/${order.id}`)}>
            {t('order.act.detail')}
          </Button>
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
    </div>
  );

  return (
    <div>
      <div className="flex flex-row">
        <div
          className={classNames(
            'box-border flex flex-col gap-[8px] py-[16px] pl-[0px] pr-[12px] text-[14px]',
            'font-bold text-grey-700 dark:text-grey-300',
            'w-1/12',
          )}
        >
          {t('order.desc.orderId')}
        </div>
        <div
          className={classNames(
            'box-border flex flex-col gap-[8px] py-[16px] pl-[0px] pr-[12px] text-[14px]',
            'font-bold text-grey-700 dark:text-grey-300',
            'w-1/6',
          )}
        >
          {t('order.desc.date')}
        </div>
        <div
          className={classNames(
            'box-border flex flex-col gap-[8px] py-[16px] pl-[0px] pr-[12px] text-[14px]',
            'font-bold text-grey-700 dark:text-grey-300',
            'w-1/6',
          )}
        >
          {t('order.desc.amount')}
        </div>
        <div
          className={classNames(
            'box-border flex flex-col gap-[8px] py-[16px] pl-[0px] pr-[12px] text-[14px]',
            'font-bold text-grey-700 dark:text-grey-300',
            'w-1/12',
          )}
        >
          {t('order.desc.unitPrice')}
        </div>
        <div
          className={classNames(
            'box-border flex flex-col gap-[8px] py-[16px] pl-[0px] pr-[12px] text-[14px]',
            'font-bold text-grey-700 dark:text-grey-300',
            'w-1/6',
          )}
        >
          {t('order.desc.total')}
        </div>
        <div
          className={classNames(
            'box-border flex flex-col gap-[8px] py-[16px] pl-[0px] pr-[12px] text-[14px]',
            'font-bold text-grey-700 dark:text-grey-300',
            'w-1/6',
          )}
        >
          {t('order.desc.awaitingPayment')}
        </div>
        <div
          className={classNames(
            'box-border flex flex-col gap-[8px] py-[16px] pl-[0px] pr-[12px] text-[14px]',
            'font-bold text-grey-700 dark:text-grey-300',
            'w-1/12',
          )}
        >
          {t('order.desc.status')}
        </div>
        <div
          className={classNames(
            'box-border flex flex-col gap-[8px] py-[16px] pl-[0px] pr-[12px] text-[14px]',
            'font-bold text-grey-700 dark:text-grey-300',
            'w-1/12',
          )}
        />
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
      {data.map(elementMapFunction)}
    </div>
  );
};

export default Table;
