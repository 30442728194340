import classNames from 'classnames';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import { Page } from 'src/constant/Page';
import { BidOrder } from 'src/model/Order';
import { bn, bnFormat } from 'src/util/bigNumber';

type Props = {
  data: BidOrder[];
};

const Table = ({ data }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const elementMapFunction = (order: BidOrder) => (
    <div key={order.id}>
      <div className="flex flex-row">
        <div
          className={classNames(
            'box-border flex flex-col gap-[8px] break-words py-[16px] pl-[0px] pr-[12px] text-[14px]',
            'w-1/12',
          )}
        >
          {order.id}
        </div>
        <div
          className={classNames(
            'box-border flex flex-col gap-[8px] break-words py-[16px] pl-[0px] pr-[12px] text-[14px]',
            'w-1/6',
          )}
        >
          {format(Date.parse(order.createdAt), 'yyyy/MM/dd HH:mm:ss')}
        </div>
        <div
          className={classNames(
            'box-border flex flex-col gap-[8px] break-words py-[16px] pl-[0px] pr-[12px] text-[14px]',
            'w-1/4',
          )}
        >
          <div>
            <div className="font-bold text-grey-700 dark:text-grey-300">
              {t('orderHistory.desc.amount')}
            </div>
            <div>{`${bnFormat(order.amount)} ${order.base.toUpperCase()}`}</div>
          </div>
          <div>
            <div className="font-bold text-grey-700 dark:text-grey-300">
              {t('orderHistory.desc.filledAmount')}
            </div>
            <div>{`${bnFormat(order.filledAmount)} ${order.base.toUpperCase()}`}</div>
          </div>
          <div>
            <div className="font-bold text-grey-700 dark:text-grey-300">
              {t('orderHistory.desc.sentAmount')}
            </div>
            <div>{`${bnFormat(order.sentAmount)} ${order.base.toUpperCase()}`}</div>
          </div>
        </div>
        <div
          className={classNames(
            'box-border flex flex-col gap-[8px] break-words py-[16px] pl-[0px] pr-[12px] text-[14px]',
            'w-1/12',
          )}
        >{`${order.price} ${order.quote.toUpperCase()}`}</div>
        <div
          className={classNames(
            'box-border flex flex-col gap-[8px] break-words py-[16px] pl-[0px] pr-[12px] text-[14px]',
            'w-1/6',
          )}
        >
          <div>
            <div className="font-bold text-grey-700 dark:text-grey-300">
              {t('orderHistory.desc.filledTotal')}
            </div>
            <div>{`${bnFormat(order.filledTotal)} ${order.quote.toUpperCase()}`}</div>
          </div>
          <div>
            <div className="font-bold text-grey-700 dark:text-grey-300">
              {t('orderHistory.desc.payment')}
            </div>
            <div>{`${bnFormat(order.sentTotal)} ${order.quote.toUpperCase()}`}</div>
          </div>
          <div>
            <div className="font-bold text-grey-700 dark:text-grey-300">
              {t('orderHistory.desc.filledSentTotal')}
            </div>
            <div>{`${bn(order.filledTotal)
              .minus(order.sentTotal)
              .toFormat()} ${order.quote.toUpperCase()}`}</div>
          </div>
        </div>
        <div
          className={classNames(
            'box-border flex flex-col gap-[8px] break-words py-[16px] pl-[0px] pr-[12px] text-[14px]',
            'w-1/6',
          )}
        >
          {order.completedAt ? format(Date.parse(order.completedAt), 'yyyy/MM/dd HH:mm:ss') : '-'}
        </div>
        <div
          className={classNames(
            'box-border flex flex-col gap-[8px] break-words py-[16px] pl-[0px] pr-[12px] text-[14px]',
            'text-center',
            'w-1/12',
          )}
        >
          <Button appearance="text" onClick={() => navigate(`${Page.Order}/${order.id}`)}>
            {t('orderHistory.act.detail')}
          </Button>
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
    </div>
  );

  return (
    <div>
      <div className="flex flex-row">
        <div
          className={classNames(
            'box-border flex flex-col gap-[8px] break-words py-[16px] pl-[0px] pr-[12px] text-[14px]',
            'font-bold text-grey-700 dark:text-grey-300',
            'w-1/12',
          )}
        >
          {t('orderHistory.desc.orderId')}
        </div>
        <div
          className={classNames(
            'box-border flex flex-col gap-[8px] break-words py-[16px] pl-[0px] pr-[12px] text-[14px]',
            'font-bold text-grey-700 dark:text-grey-300',
            'w-1/6',
          )}
        >
          {t('orderHistory.desc.createDate')}
        </div>
        <div
          className={classNames(
            'box-border flex flex-col gap-[8px] break-words py-[16px] pl-[0px] pr-[12px] text-[14px]',
            'font-bold text-grey-700 dark:text-grey-300',
            'w-1/4',
          )}
        >
          {`${t('orderHistory.desc.orderAmount')}`}
        </div>
        <div
          className={classNames(
            'box-border flex flex-col gap-[8px] break-words py-[16px] pl-[0px] pr-[12px] text-[14px]',
            'font-bold text-grey-700 dark:text-grey-300',
            'w-1/12',
          )}
        >
          {t('orderHistory.desc.unitPrice')}
        </div>
        <div
          className={classNames(
            'box-border flex flex-col gap-[8px] break-words py-[16px] pl-[0px] pr-[12px] text-[14px]',
            'font-bold text-grey-700 dark:text-grey-300',
            'w-1/6',
          )}
        >
          {`${t('orderHistory.desc.total')}`}
        </div>
        <div
          className={classNames(
            'box-border flex flex-col gap-[8px] break-words py-[16px] pl-[0px] pr-[12px] text-[14px]',
            'font-bold text-grey-700 dark:text-grey-300',
            'w-1/6',
          )}
        >
          {t('orderHistory.desc.completeDate')}
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
      {data.map(elementMapFunction)}
    </div>
  );
};

export default Table;
