import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import { Page } from 'src/constant/Page';
import { BidOrder } from 'src/model/Order';
import { bn, bnFormat } from 'src/util/bigNumber';

type Props = {
  data: BidOrder[];
};

const Card = ({ data }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const elementMapFunction = (order: BidOrder) => (
    <div key={order.id}>
      <div className="relative flex flex-col gap-[4px] px-[0px] py-[15px]">
        <div className="flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('order.desc.orderId')}
          </div>
          <div>{order.id}</div>
        </div>
        <div className="flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">{t('order.desc.date')}</div>
          <div>{format(Date.parse(order.createdAt), 'yyyy/MM/dd HH:mm:ss')}</div>
        </div>
        <div className="flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('order.desc.orderAmount')}
          </div>
          <div>{`${bnFormat(order.amount)} ${order.base.toUpperCase()}`}</div>
        </div>
        <div className="flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('order.desc.filledAmount')}
          </div>
          <div>{`${bnFormat(order.filledAmount)} ${order.base.toUpperCase()}`}</div>
        </div>
        <div className="flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('order.desc.sentAmount')}
          </div>
          <div>{`${bnFormat(order.sentAmount)} ${order.base.toUpperCase()}`}</div>
        </div>
        <div className="flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('order.desc.unitPrice')}
          </div>
          <div>{`${bnFormat(order.price)} ${order.quote.toUpperCase()}`}</div>
        </div>
        <div className="flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('order.desc.filledTotal')}
          </div>
          <div>{`${bnFormat(order.filledTotal)} ${order.quote.toUpperCase()}`}</div>
        </div>
        <div className="flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('order.desc.paidTotal')}
          </div>
          <div>{`${bnFormat(order.sentTotal)} ${order.quote.toUpperCase()}`}</div>
        </div>
        <div className="flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('order.desc.awaitingPayment')}
          </div>
          <div>{`${bn(order.filledTotal)
            .minus(bn(order.sentTotal))
            .toFormat()} ${order.quote.toUpperCase()}`}</div>
        </div>
        <div className="flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">{t('order.desc.status')}</div>
          <div className="text-green dark:text-lime">
            {t(`order.desc.statusDisplay.${order.status}`)}
          </div>
        </div>
        <div className="absolute bottom-[15px] right-0">
          <Button appearance="text" onClick={() => navigate(`${Page.Order}/${order.id}`)}>
            {t('order.act.detail')}
          </Button>
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
    </div>
  );

  return <div>{data.map(elementMapFunction)}</div>;
};

export default Card;
