import { ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import Modal from 'src/component/Modal';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (file: File) => void;
};

const UploadModal = ({ open, onClose, onSubmit }: Props) => {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) onSubmit(e.target.files[0]);
  };

  return (
    <Modal open={open} handleClose={onClose}>
      <div>
        <div className="mb-[20px] text-[32px] font-bold">{t('orderDetail.desc.uploadHeading')}</div>
        <div>{t('orderDetail.desc.uploadContent')}</div>
        <br />
        <div>{t('orderDetail.desc.uploadAccept')}</div>
        <div>{t('orderDetail.desc.uploadLimit')}</div>
        <div className="mt-[40px] flex flex-row justify-end gap-[20px]">
          <Button appearance="outlined" onClick={onClose} type="button">
            {t('act.cancel')}
          </Button>
          <Button type="button" onClick={() => fileInputRef.current?.click()}>
            {t('orderDetail.act.uploadFile')}
          </Button>
          <input
            type="file"
            onChange={handleChange}
            ref={fileInputRef}
            className="hidden"
            accept="image/png,image/jpeg"
          />
        </div>
      </div>
    </Modal>
  );
};

export default UploadModal;
