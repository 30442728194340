import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import Modal from 'src/component/Modal';

type Props = {
  open: boolean;
  onClose: () => void;
};

const UncancelModal = ({ open, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} handleClose={onClose}>
      <div>
        <div>{t('orderDetail.desc.uncancelableMessage')}</div>
        <div className="mt-[40px] flex flex-row justify-end gap-[20px]">
          <Button appearance="outlined" onClick={onClose} type="button">
            {t('act.ok')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UncancelModal;
