import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import PublicBar from 'src/component/PublicBar';
import { Page } from 'src/constant/Page';
import IcDownloadApp from 'src/image/downloadapp-maibee.svg';
import LandingpageMaibee from 'src/image/landingpage-maibee.svg';
import { RootState } from 'src/redux/store';
import Footer from './component/Footer';

const LandingBid = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLogin } = useSelector((state: RootState) => state.auth);

  return (
    <div>
      <PublicBar />
      <div className="px-[15px] py-0 xs:mx-auto xs:my-0 xs:w-[85%] xs:max-w-[1200px]">
        <div className="sm:min-h-[440px] sm:bg-[url('src/image/landingpage-maibee-l.svg')] sm:bg-right-top sm:bg-no-repeat sm:pb-[70px] sm:pt-[40px] lg:min-h-[540px]">
          <div className="flex justify-center pb-[20px] pt-[40px] text-[32px] font-bold leading-[1.2] sm:block sm:w-[381px] sm:text-[56px]">
            {t('landing.heading')}
          </div>
          <div className="flex w-full flex-col items-center sm:block">
            <Button
              className="mb-auto mt-0 w-[180px]"
              size="large"
              onClick={() => (isLogin ? navigate(Page.Trade) : navigate(Page.SignIn))}
            >
              {t('landing.act.startNow')}
            </Button>
          </div>
          <div className="px-[15px] pb-[70px] pt-[40px] text-center sm:hidden sm:px-[40px]">
            <img className="w-full" src={LandingpageMaibee} />
          </div>
        </div>
        <div className="mt-[70px] flex flex-col xs:flex-row">
          <div className="w-full p-[20px] xs:flex xs:w-[50%] xs:flex-col xs:justify-center">
            <div className="text-[27.2px] font-bold">{t('landing.desc.appTitle')}</div>
            <div className="mx-0 my-[20px] text-[24px] font-bold">{t('landing.desc.appDesc')}</div>
            <div className="mt-[20px] flex">
              <Button
                className="mx-auto my-0 text-[16px] font-bold xs:m-0"
                appearance="text"
                onClick={() => (location.href = '/download/ios')}
              >
                {t('landing.act.downloadIos')}
              </Button>
            </div>
            <div className="mt-[20px] flex">
              <Button
                className="mx-auto my-0 text-[16px] font-bold xs:m-0"
                appearance="text"
                onClick={() => (location.href = '/download/android/maibee.apk')}
              >
                {t('landing.act.downloadAndroid')}
              </Button>
            </div>
          </div>
          <div className="w-full p-[20px] text-center xs:order-[-1] xs:w-[50%]">
            <img className="w-full" src={IcDownloadApp} />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default LandingBid;
