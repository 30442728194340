import { differenceInMilliseconds, format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Alert from 'src/component/Alert';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import Tabs from 'src/component/Tabs';
import { MediaQuery } from 'src/constant/Media';
import { Severity } from 'src/constant/Notification';
import { BidOrder, Fill } from 'src/model/Order';
import { openSnackbar } from 'src/redux/uiSlice';
import { cancelOrder, getBidOrderId, getOrderFills } from 'src/service/orderService';
import { bn, bnFormat } from 'src/util/bigNumber';
import { formatDuration } from 'src/util/datetimeHelper';
import CancelModal from './component/CancelModal';
import OrderFillList from './component/OrderFillList';
import UncancelModal from './component/UncancelModal';

const OrderBidDetail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orderId } = useParams();
  const location = useLocation();
  const state = location.state as BidOrder | null;
  const [userOrder, setUserOrder] = useState<BidOrder>();
  const [executedFills, setExecutedFills] = useState<Array<Fill>>();
  const [sentReceivedFills, setSentReceivedFills] = useState<Array<Fill>>();
  const [cancelable, setCancelable] = useState<boolean>();
  const [cancelableModalOpen, setCancelableModalOpen] = useState<boolean>(false);
  const [uncancelableModalOpen, setUncancelableModalOpen] = useState<boolean>(false);
  const [orderRefresh, setOrderRefresh] = useState<boolean>(false);
  const [fillsRefresh, setFillsRefresh] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const isBiggerThanXsm = useMediaPredicate(MediaQuery.Xsm);
  const [countdown, setCountdown] = useState<string>();

  useEffect(() => {
    if (state === null) return;
    setUserOrder(state);
    if (state.status === 'open')
      dispatch(
        openSnackbar({
          message: t('orderDetail.desc.notifyOpen', {
            expiredAt: state.expiredAt ? format(Date.parse(state.expiredAt), 'HH:mm') : '-:-',
          }),
          severity: 'notify',
        }),
      );
    if (state.status === 'executed')
      dispatch(openSnackbar({ message: t('orderDetail.desc.notifyExecuted'), severity: 'notify' }));
  }, [t, state, orderId]);

  useEffect(() => {
    if (orderId === undefined || orderId.length === 0) return;
    getBidOrderId(orderId)
      .then((res) => setUserOrder(res))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [orderId, orderRefresh]);

  useEffect(() => {
    if (!orderId || orderId.length === 0) return;

    getOrderFills(orderId)
      .then((res) => {
        setExecutedFills(res.executedFills);
        setSentReceivedFills(res.sentReceviedFills);
        setCancelable(res.cancelable);
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [orderId, fillsRefresh]);

  useEffect(() => {
    if (!userOrder || !userOrder.expiredAt) return;

    if (countdown && new Date(userOrder.expiredAt) < new Date()) {
      navigate(0);

      return;
    }
    if (new Date(userOrder.expiredAt) < new Date()) return;
    const timer = setTimeout(() => {
      const diff = userOrder.expiredAt
        ? differenceInMilliseconds(new Date(userOrder.expiredAt), new Date())
        : 0;
      setCountdown(formatDuration(diff));
    }, 1000);

    return () => clearTimeout(timer);
  }, [userOrder?.expiredAt, countdown]);

  const onCancelClick = () => {
    if (cancelable) setCancelableModalOpen(true);
    else setUncancelableModalOpen(true);
  };

  const onRefresh = () => {
    setOrderRefresh(!orderRefresh);
    setFillsRefresh(!fillsRefresh);
  };

  const onCancelOrder = () => {
    if (orderId === undefined) return;

    setCancelableModalOpen(false);
    cancelOrder(orderId)
      .then(onRefresh)
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  if (userOrder === undefined) return <div />;

  return (
    <div className="box-border flex h-full flex-col !pb-[60px] sm:!pb-[90px]">
      <BackButton />
      <div className="mb-[15px] mt-[10px] text-[28px] font-bold sm:mt-[20px] sm:text-[32px]">
        {t('orderDetail.heading', { id: orderId })}
      </div>
      <div className="flex">
        <div className="flex w-full flex-wrap gap-y-[15px] sm:w-3/4">
          <div className="w-[calc((100%)/3)] text-[12px]">
            <div className="text-grey-700 dark:text-grey-300">{t('orderDetail.desc.status')}</div>
            <div className="text-[20px] text-green dark:text-lime">
              {t(`orderDetail.desc.statusDisplay.${userOrder.status}`, {
                expiredAt: userOrder.expiredAt
                  ? format(Date.parse(userOrder.expiredAt), 'HH:mm')
                  : '-:-',
                countdown: countdown ?? '-:-',
              })}
            </div>
          </div>
          <div className="w-[calc((100%)/3)] text-[12px]">
            <div className="text-grey-700 dark:text-grey-300">
              {t('orderDetail.desc.createdDate')}
            </div>
            <div className="whitespace-pre-line">
              {format(Date.parse(userOrder.createdAt), 'yyyy/MM/dd HH:mm:ss')}
            </div>
          </div>
          <div className="w-[calc((100%)/3)] text-[12px]">
            <div className="text-grey-700 dark:text-grey-300">
              {t('orderDetail.desc.unitPrice')}
            </div>
            <div className="whitespace-pre-line">{`${bnFormat(
              userOrder.price,
            )} ${userOrder.quote.toUpperCase()}`}</div>
          </div>
          <div className="w-[calc((100%)/3)] text-[12px]">
            <div className="text-grey-700 dark:text-grey-300">
              {t('orderDetail.desc.orderAmount')}
            </div>
            <div className="whitespace-pre-line">{`${bnFormat(
              userOrder.amount,
            )} ${userOrder.base.toUpperCase()}`}</div>
          </div>
          <div className="w-[calc((100%)/3)] text-[12px]">
            <div className="text-grey-700 dark:text-grey-300">
              {t('orderDetail.desc.filledAmount')}
            </div>
            <div className="whitespace-pre-line">
              {`${bnFormat(userOrder.filledAmount)} ${userOrder.base.toUpperCase()}`}
            </div>
          </div>
          <div className="w-[calc((100%)/3)] text-[12px]">
            <div className="text-grey-700 dark:text-grey-300">
              {t('orderDetail.desc.filledTotal')}
            </div>
            <div className="whitespace-pre-line">
              {`${bnFormat(userOrder.filledTotal)} ${userOrder.quote.toUpperCase()}`}
            </div>
          </div>
        </div>
        {isBiggerThanXsm && (
          <div className="flex w-1/4 items-end justify-end">
            <Button appearance="outlined" onClick={onRefresh}>
              {t('orderDetail.act.refresh')}
            </Button>
          </div>
        )}
      </div>
      {!isBiggerThanXsm && (
        <div className="relative mt-[15px]">
          <Tabs
            labels={[t('orderDetail.act.awaitingPayment'), t('orderDetail.act.completedPayment')]}
            onChange={(index) => setTabIndex(index)}
            defaultIndex={tabIndex}
          />
          <Button className="absolute right-0 top-0" appearance="outlined" onClick={onRefresh}>
            {t('orderDetail.act.refresh')}
          </Button>
        </div>
      )}
      <div className="mt-[15px] flex grow flex-row gap-[20px] overflow-y-hidden xs:mt-[20px]">
        {(isBiggerThanXsm || tabIndex === 0) && (
          <div className="flex flex-1 flex-col overflow-y-hidden rounded-[4px] bg-white dark:bg-black-800">
            <div className="p-[15px] text-[13px] font-bold">
              <div className="flex flex-row items-center">
                <div>{t('orderDetail.desc.awaitingPayment')}:</div>
                <div className="text-turquoise dark:text-aqua">
                  {executedFills
                    ?.reduce((sum, current) => sum.plus(bn(current.total)), bn(0))
                    ?.toFormat()}
                </div>
                <div>&nbsp;{userOrder.quote.toUpperCase()}</div>
              </div>
              <div className="flex flex-row items-center">
                <div>{t('orderDetail.desc.amount')}:</div>
                <div className="text-turquoise dark:text-aqua">
                  {executedFills
                    ?.reduce((sum, current) => sum.plus(bn(current.amount)), bn(0))
                    ?.toFormat()}
                </div>
                <div>&nbsp;{userOrder.base.toUpperCase()}</div>
              </div>
            </div>
            <div className="h-[1px] bg-light-400 pb-[0.5px] dark:bg-dark-500" />
            <OrderFillList
              fillsArray={executedFills}
              onRefresh={() => setFillsRefresh(!fillsRefresh)}
              className="grow overflow-y-scroll px-[15px] py-[0px]"
            />
          </div>
        )}
        {(isBiggerThanXsm || tabIndex === 1) && (
          <div className="flex flex-1 flex-col overflow-y-hidden rounded-[4px] bg-white dark:bg-black-800">
            <div className="p-[15px] text-[13px] font-bold">
              <div className="flex flex-row items-center">
                <div>{t('orderDetail.desc.finishedPayment')}:</div>
                <div className="text-turquoise dark:text-aqua">
                  {sentReceivedFills
                    ?.reduce((sum, current) => sum.plus(bn(current.total)), bn(0))
                    ?.toFormat()}
                </div>
                <div>&nbsp;{userOrder.quote.toUpperCase()}</div>
              </div>
              <div className="flex flex-row items-center">
                <div>{t('orderDetail.desc.amount')}:</div>
                <div className="text-turquoise dark:text-aqua">
                  {sentReceivedFills
                    ?.reduce((sum, current) => sum.plus(bn(current.amount)), bn(0))
                    ?.toFormat()}
                </div>
                <div>&nbsp;{userOrder.base.toUpperCase()}</div>
              </div>
            </div>
            <div className="h-[1px] bg-light-400 pb-[0.5px]  dark:bg-dark-500" />
            <OrderFillList
              fillsArray={sentReceivedFills}
              onRefresh={() => setFillsRefresh(!fillsRefresh)}
              className="grow overflow-y-scroll px-[15px] py-[0px]"
            />
          </div>
        )}
      </div>
      {userOrder.status !== 'completed' && (
        <div className="mt-[15px] flex flex-row items-center">
          <Alert severity={Severity.Warning} className="grow">
            {userOrder.canceledAt
              ? t('orderDetail.desc.noticeOnOrderFinished')
              : t('orderDetail.desc.noticeAboutFinishingOrder')}
          </Alert>
          {!userOrder.canceledAt && (
            <Button
              appearance="text"
              className="h-full px-[10px] py-[0px] text-crimson dark:text-red"
              onClick={onCancelClick}
            >
              {t('orderDetail.act.finishOrder')}
            </Button>
          )}
        </div>
      )}
      <CancelModal
        open={cancelableModalOpen}
        onClose={() => setCancelableModalOpen(false)}
        onSubmit={onCancelOrder}
      />
      <UncancelModal open={uncancelableModalOpen} onClose={() => setUncancelableModalOpen(false)} />
    </div>
  );
};

export default OrderBidDetail;
